const fileHost = "https://oms-private.oss-cn-shanghai.aliyuncs.com/OMS_FOLDER";
const baseURL = "https://testoms.geezdata.com/";
const redirectUri = "https://testoms.geezdata.com/oms_api/oauth/lazada";
const scmURL = "https://testscm.shopspade.com/";
const idaasURL = "https://testidaas.shopspade.com";
const frontURL = "https://testoms.geezdata.com";
const aftersaleURL = "https://testaftersale.shopspade.com/api/";
const regionURL = "https://testregion.shopspade.com/api/";
const inventoryURL = "https://testinventory.shopspade.com/api/";
const reportURL = "https://testtask.shopspade.com/api/";
const orderURL = "https://testorder.shopspade.com/api/";
const plymouthURL = "https://testplymouth.shopspade.com/api/";
const idaasApi = "https://testidaas.shopspade.com/api/";
const goodsApi = "https://testproduct.shopspade.com/api/";
const shopApi = "https://testshop.shopspade.com/api/";
const workorderApi = "https://testworkorder.shopspade.com/api/";
const myjApi = "https://myj.shopspade.com/api/";

const aftersaleWebEntry = "https://testaftersale.shopspade.com";
const workorderWebEntry = "https://testworkorder.shopspade.com";
const scmWebEntry = "https://testscm.shopspade.com";
const inventoryWebEntry = "https://testinventory.shopspade.com";
const reportWebEntry = "https://testtask.shopspade.com";
const biErpWebEntry = "https://testbi.shopspade.com";
const bestsellerEntry = "https://testbestsellers.shopspade.com";
const wmshubWebEntry = "https://testwmshub.shopspade.com";
const campaignWebEntry = "https://testcampaign.shopspade.com";
const prepaymentEntry = "https://testprepayment.shopspade.com";
const shopWebEntry = "https://testshop.shopspade.com";
const strategyWebEntry = "https://teststrategy.shopspade.com"; //策略中心
const goodsWebEntry = "https://testproduct.shopspade.com"; //商品
const regionWebEntry = "https://testregion.shopspade.com"; //地址库
const biEntry = "https://testbi.shopspade.com/qbiDashboard"
const orderWebEntry = "https://testorder.shopspade.com"; //订单
const financeWebEntry = "https://testfms.geezdata.com"; //财务

const wsURL = 'wss://testplymouth.shopspade.com/wsapi/'
const singleSignOnURL =
  idaasURL + '/api/open/oauth/authorize?app_key=spade_gateway_001&response_type=code&scope=OMS&terminal=PC&redirect_uri=' +
  frontURL + '&idaas_uri=' + idaasURL
const singleSignOutURL = idaasURL + '/logout'


export {
  baseURL,
  redirectUri,
  fileHost,
  scmURL,
  idaasURL,
  frontURL,
  aftersaleURL,
  regionURL,
  inventoryURL,
  reportURL,
  orderURL,
  plymouthURL,
  idaasApi,
  goodsApi,
  shopApi,
  workorderApi,
  aftersaleWebEntry,
  workorderWebEntry,
  scmWebEntry,
  inventoryWebEntry,
  reportWebEntry,
  biErpWebEntry,
  bestsellerEntry,
  wmshubWebEntry,
  campaignWebEntry,
  prepaymentEntry,
  shopWebEntry,
  strategyWebEntry,
  goodsWebEntry,
  regionWebEntry,
  biEntry,
  wsURL,
  myjApi,
  orderWebEntry,
  financeWebEntry,
  singleSignOnURL,
  singleSignOutURL
};
